import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { API_URL, BASE_URL, WEBSITE } from "../../constants/Constants";

// const API_URL = "http://localhost:3001/";
// const BASE_URL = "http://localhost:3000/";

const Head = () => {
  const [settings, setSettings] = useState("");
  useEffect(() => {
    try {
      axios
        .get(`${API_URL}setting/basic/${WEBSITE}`)
        .then((response) => {
          //console.log(response.data);
          setSettings(response.data);
        })
        .catch((error) => {
          alert(error.message);
        });
    } catch (error) {
      alert(error.message);
    }
  }, [setSettings]);

  return (
    <>
      <Helmet>
        <title>Home - {`${settings.metaTitle}`}</title>
        <link rel="canonical" href={`${BASE_URL}about`} />
        <link rel="icon" href={`${BASE_URL}favicon.ico`}/>
        <meta name="description" content={`${settings.metaDescription}`} />
        <meta name="keywords" content={`${settings.metaKeyword}`} />
      </Helmet>
      <section className="head">
        <div className="container">
          <div className="subContainer flexSB">
            <div className="logo">
              <div className="header-logo-part">
                <div className="header-logo">
                  {/* <img
                    src={`./images/website/${settings.logo}`}
                    alt={settings.title}
                  /> */}
                  <NavLink to={BASE_URL}>
                    <img
                      src={`${API_URL}images/website/${settings.logo}`}
                      alt={`${settings.title}`}
                    />
                  </NavLink>
                </div>
                <div className="header-details">
                  <h1>{settings.title}</h1>
                  <span>{settings.subTitle}</span>
                </div>
              </div>
            </div>
            <div className="founded">
              <h2>Founded: 2014</h2>
            </div>
            <div className="social">
              <a
                href={settings.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f icon"></i>
              </a>
              <a
                href={settings.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram icon"></i>
              </a>
              <a
                href={settings.twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter icon"></i>
              </a>
              <a
                href={settings.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin icon"></i>
              </a>
              <a
                href={settings.youtube}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube icon"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
