import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../../helpers/AuthContext";
import { BASE_URL } from "../../constants/Constants";
import Head from "./Head";
import "./header.css";

const Header = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const [click, setClick] = useState(false);
  const [activeItem, setActiveItem] = useState("home");
  const [activeAdminItem, setActiveAdminItem] = useState("dashboard");
  const navigate = useNavigate();
  const match = useLocation();

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  const handleAdminItemClick = (itemName) => {
    setActiveAdminItem(itemName);
  };

  const logout = () => {
    try {
      localStorage.removeItem("accessToken");
      //console.log(response.data);
      setAuthState({
        username: "",
        email: "",
        id: 0,
        role: "",
        task: "",
        status: false,
      });
      navigate("/");
    } catch (error) {
      alert(error.message);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  },[match.pathname]);

  return (
    <>
      <Head />
      <header className="sticky">
        <div className="container">
          <nav className="subContainer">
            {authState.status ? (
              <div
                className={click ? "mobile-nav" : "desktop-nav"}
                onClick={() => setClick(false)}
              >
                <div className="left-part">
                  {authState.task.match(new RegExp(`(?:Dashboard|All)`, 'g')) ? (
                    <Link
                      to="/admin/dashboard"
                      className={`menu-item ${
                        activeAdminItem === "dashboard" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("dashboard")}
                    >
                      Dashboard
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:New-Admission|All)`, 'g')) ? (
                    <Link
                      to="/admin/students"
                      className={`menu-item ${
                        activeAdminItem === "students" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("students")}
                    >
                      New-Admission
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:Courses|All)`, 'g')) ? (
                    <Link
                      to="/admin/courses"
                      className={`menu-item ${
                        activeAdminItem === "courses" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("courses")}
                    >
                      Courses
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:Results|All)`, 'g')) ? (
                    <Link
                      to="/admin/results"
                      className={`menu-item ${
                        activeAdminItem === "results" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("results")}
                    >
                      Results
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:Blogs|All)`, 'g')) ? (
                    <Link
                      to="/admin/blogs"
                      className={`menu-item ${
                        activeAdminItem === "blogs" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("blogs")}
                    >
                      Blogs
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:Staff|All)`, 'g')) ? (
                    <Link
                      to="/admin/staff"
                      className={`menu-item ${
                        activeAdminItem === "staff" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("staff")}
                    >
                      Staff
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:Youtube|All)`, 'g')) ? (
                    <Link
                      to="/admin/youtube"
                      className={`menu-item ${
                        activeAdminItem === "youtube" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("youtube")}
                    >
                      YouTube
                    </Link>
                  ) : null}
                  {authState.task.match(new RegExp(`(?:Reviews|All)`, 'g')) ? (
                    <Link
                      to="/admin/testimonials"
                      className={`menu-item ${
                        activeAdminItem === "testimonials" ? "active" : ""
                      }`}
                      onClick={() => handleAdminItemClick("testimonials")}
                    >
                      Reviews
                    </Link>
                  ) : null}
                </div>
                <div className="right-part">
                  <div className="authLogout">
                    <h2>{authState.username}</h2>
                    {authState.status && (
                      <span onClick={logout}>
                        <img
                          src={`${BASE_URL}images/basic-icons/logout.png`}
                          alt="logout"
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={click ? "mobile-nav" : "desktop-nav"}
                onClick={() => setClick(false)}
              >
                <div className="left-part">
                  <Link
                    to="/"
                    className={`menu-item ${
                      activeItem === "home" ? "active" : ""
                    }`}
                    onClick={() => handleItemClick("home")}
                  >
                    Home
                  </Link>
                  <Link
                    to="/courses"
                    className={`menu-item ${
                      activeItem === "courses" ? "active" : ""
                    }`}
                    onClick={() => handleItemClick("courses")}
                  >
                    Courses
                  </Link>
                  <Link
                    to="/blogs"
                    className={`menu-item ${
                      activeItem === "blogs" ? "active" : ""
                    }`}
                    onClick={() => handleItemClick("blogs")}
                  >
                    Blogs
                  </Link>
                  <Link
                    to="/result"
                    className={`menu-item ${
                      activeItem === "result" ? "active" : ""
                    }`}
                    onClick={() => handleItemClick("result")}
                  >
                    Result
                  </Link>
                  <Link
                    to="/about"
                    className={`menu-item ${
                      activeItem === "about" ? "active" : ""
                    }`}
                    onClick={() => handleItemClick("about")}
                  >
                    About
                  </Link>
                  <Link
                    to="/contact"
                    className={`menu-item ${
                      activeItem === "contact" ? "active" : ""
                    }`}
                    onClick={() => handleItemClick("contact")}
                  >
                    Contact
                  </Link>
                </div>
                <div className="right-part">
                  <Link to="/apply">Apply Now</Link>
                </div>
              </div>
            )}
            <button className="toggle" onClick={() => setClick(!click)}>
              {click ? (
                <i className="fa fa-times"></i>
              ) : (
                <i className="fa fa-bars"></i>
              )}
            </button>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
